.CareSection {
  background-color: #dbf2f8;
  padding-top: 50px;
  padding-bottom: 50px;
}

.CareSection h2,
.CareSection p {
  margin: 0 70px;
}

.mediaRight {
  display: flex;
  margin: 50px 70px;
}

.round-image {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  box-shadow: 0 10px 20px 0 rgba(39, 38, 38, 0.3);
  margin: 0 50px;
}

.main-heading {
  color: #558103;
}

.inner-text-heading {
  font-size: 45px;
  color: #0d58a6;
  text-align: left;
  width: 800px;
}

.image-container-home {
  display: flex;
}

@media only screen and (max-width: 850px) {
  .mediaRight {
    margin: 20px 30px;
  }

  .CareSection h2,
  .CareSection p {
    margin: 0px 30px;
  }

  .image-container-home {
    flex-direction: column;
  }

  .round-image {
    margin: 10px 10px;
  }

  .inner-text-heading {
    width: 500px;
    font-size: 40px;
  }

  #second-image {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .inner-text-heading {
    width: 320px;
    font-size: 30px;
  }

  .mediaRight {
    flex-direction: column-reverse;
  }

  #second-image {
    display: none;
  }
}
