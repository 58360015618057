.button-colored {
  background-color: #7fc602;
  color: white;
  margin-left: 20px;
  padding: 0 20px 0 20px;
  height: 50px;
  border: 2px solid lightblue;
  border-radius: 5px;
  background: linear-gradient(to bottom, #7fc602, #5f9501);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 980px) {
  .button-colored {
    height: 70px;
    margin-bottom: 5px;
  }
}
