.containerDivMediaCard {
  display: flex;
  justify-content: space-evenly;
  background-color: #dbf2f8;
}

@media only screen and (max-width: 980px) {
  .containerDivMediaCard {
    flex-wrap: wrap;
  }
}
