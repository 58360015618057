.hear-section {
  display: flex;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(2555, 255, 255, 0.5)
    ),
    url("../../../public/Assets/Home Page/Hear from you.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  justify-content: center;
}
.love-to-hear-section {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  width: 700px;
  margin: 70px;
  border: 1px solid lightgray;
}
.love-to-hear-section img {
  margin-top: 20px;
  width: 200px;
}
.love-to-hear-section p {
  text-align: center;
  padding: 0px 80px;
}

.underline-text:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 980px) {
  .hear-section {
    background-size: initial;
  }

  .love-to-hear-section {
    margin: 0px;
    background-color: rgba(255, 255, 255, 0);
  }
}

@media only screen and (max-width: 500px) {
  .love-to-hear-section {
    margin: 0px;
    background-color: rgba(255, 255, 255, 0.5);
  }
}
