.main-container {
  display: flex;
  flex-direction: column;
}

.inner-container-services {
  display: flex;
  justify-content: space-around;
  padding: 0px 20px;
}

.additional-service-container {
  display: flex;
  justify-content: space-evenly;
}

@media only screen and (max-width: 980px) {
  .inner-container-services {
    flex-wrap: wrap;
  }

  .additional-service-container {
    flex-wrap: wrap;
  }
}
