.bordered-div-vaccine {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
  margin: 50px 410px;
  border-radius: 4px;
  border: 1px solid lightgray;
  padding: 40px;
}

.patient-div-vaccine {
  display: flex;
}

.form-div-vaccine {
  margin-bottom: 20px;
  width: 300px;
}

.link-color {
  color: #1a84ff;
  text-decoration: none;
}

.link-color:hover {
  text-decoration: underline;
}

.vaccine-form-inputs {
  display: flex;
}

@media only screen and (max-width: 1280px) {
  .bordered-div-vaccine {
    margin: 30px 300px;
    /* width: 70%; */
  }
}

@media only screen and (max-width: 980px) {
  .bordered-div-vaccine {
    margin: 30px 30px;
  }
}
