.container-media-insurance {
  margin: 20px 50px;
}

.account-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homePage_Titletext {
  background-color: #dbf2f8;
  padding: 10px 0px;
}
