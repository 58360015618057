.form-outer-div {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid lightgray;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
  align-items: center;
  margin: 70px 350px;
  width: 800px;
  height: 510px;
}

.title-div {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #0d58a6;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
}

.title-icon {
  margin: 0 20px;
}

.two-inputs {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.label-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
}

.last-input {
  width: 95%;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.step2-last-input {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.btn-div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 40px;
}

.form-label {
  font-weight: bold;
  font-size: 15px;
  margin: 5px 30px;
}

.menu-option {
  height: 40px;
  margin: 10px 30px;
  border-radius: 4px;
  border: 2px solid lightgray;
}

.modal-content {
  background-color: white;
  padding: 50px;
}

.step-3-buttons {
  display: flex;
  justify-content: space-evenly;
}

@media only screen and (max-width: 980px) {
  .form-outer-div {
    margin: 30px 30px;
    width: 600px;
    height: 100%;
  }

  .step2-last-input {
    width: 90%;
  }

  .btn-div {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .form-outer-div {
    margin: 15px 15px;
    width: 350px;
    height: 100%;
  }

  .two-inputs {
    flex-direction: column;
  }

  .label-input {
    width: 90%;
    margin-bottom: 0px;
  }

  .last-input {
    width: 90%;
    margin-bottom: 20px;
  }

  .form-label {
    margin: 5px 15px;
  }

  .btn-div {
    margin-bottom: 20px;
  }

  .step-3-buttons {
    margin-bottom: 20px;
  }
}
