.outer-container {
  display: flex;
  margin-bottom: 50px;
}

.left-image-div {
  height: 100px;
  width: 100px;
  text-align: center;
  border-radius: 50%;
  background-color: #7fc602;
  margin-right: 30px;
}

.inner-Image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

@media only screen and (max-width: 980px) {
  .outer-container {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .left-image-div {
    height: 80px;
    width: 80px;
    margin-right: 10px;
  }

  .inner-Image {
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 500px) {
  .outer-container {
    justify-content: center;
  }
}
