.container-media {
  display: flex;
  flex-direction: column;
}

.media-top {
  border-bottom: 2px solid green;
  justify-content: space-around;
}

.image-div {
  height: 200px;
  width: 300px;
  padding: 30px 0px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  overflow: hidden;
}

.inner-image {
  max-width: 100%;
  max-height: 100%;
}

.inner-image:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
}

.media-button {
  width: 200px;
  height: 50px;
  text-align: center;
  background-color: white;
  border: none;
  margin-left: 50px;
  position: relative;
  bottom: 20px;
  border-radius: 4px;
  cursor: pointer;
}

.media-bottom {
  text-align: center;
  width: 300px;
  margin-bottom: 40px;
}
