.insider-section {
  display: flex;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../public/Assets/Home Page/Immunization 1.jpeg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  justify-content: center;
  color: #fff;
  min-height: 350px;
}

.insider-section div {
  text-align: center;
}

@media only screen and (max-width: 980px) {
  .pharmacy-insider-content {
    text-align: center;
    margin: 10px;
  }
}
