.overlay-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the alpha (fourth value) for transparency */
}
.background-image {
  background-repeat: no-repeat;
  background-size: cover;
}
.title-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 36px;
}
.overlay-slider h2 {
  margin: 0;
}
.overlay-slider p {
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .title-slider {
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    padding: 5px;
  }

  .background-image {
    background-size: 100% 100%;
  }
}
