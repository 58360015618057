.pageHeading {
  background-color: #7fc602;
  display: flex;
  justify-content: center;
  color: white;
  margin: 70px;
}

@media only screen and (max-width: 980px) {
  .pageHeading {
    margin: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .pageHeading {
    justify-content: flex-start;
    padding-left: 20px;
  }
}
