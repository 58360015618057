.image-details {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 50px 20px 50px;
}

.image-details-image-container {
  flex: 1;
}

.image-details-image {
  width: 100%;
  height: auto;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
}

.image-details-details {
  flex: 2;
  margin-right: 30px;
}

.image-details-title {
  margin-top: 0;
  color: #0b59a6;
  margin-bottom: 10px;
}

.image-details-description {
  margin-bottom: 0;
}

.bullet-list {
  list-style-type: disc;
}

@media only screen and (max-width: 850px) {
  .image-details {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 500px) {
  .image-details {
    flex-direction: column-reverse;
  }
}
