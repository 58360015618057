.containerDiv {
  display: flex;
  justify-content: space-around;
  background-color: white;
  padding: 50px;
}

@media only screen and (max-width: 980px) {
  .containerDiv {
    flex-wrap: wrap;
  }
}
