.header {
    color: rgba(255,255,255);
    background-color: rgba(204,0,0);
    padding: 10px;
    display: block;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
}
.link {
    text-decoration: underline;
    color: #fff;
}