.containerDivTestimonial {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 980px) {
  .containerDivTestimonial {
    flex-wrap: wrap;
  }
}
