.container {
  background-color: #0d58a6;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
}

.flexDisplay {
  display: flex;
}

.underlineText:hover {
  text-decoration: underline;
}

.map-link {
  text-decoration: none;
  color: #fff;
}

@media only screen and (max-width: 980px) {
  .container {
    flex-wrap: wrap;
    height: 100px;
  }

  .flexDisplay {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    display: none;
  }
}
