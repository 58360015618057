.containerCards {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 500px) {
  .containerCards {
    flex-wrap: wrap;
  }
}
