.text-container {
  margin: 40px 100px;
}

.main-text {
  color: #558103;
}

.secondary-text {
  color: #0d58a6;
}

@media only screen and (max-width: 980px) {
  .text-container {
    margin: 20px 50px;
  }
}

@media only screen and (max-width: 500px) {
  .text-container {
    margin: 20px 20px;
  }
}
