.review-card {
  border: 1px solid #7fc602;
  position: relative;
  overflow: hidden;
}

.review-card::before {
  content: "";
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #7fc602;
  transition: top 0.7s ease-in-out;
}

.review-card:hover::before {
  top: 100%;
  transition: top 0.7s ease-in-out;
}

.card-header-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.card-header-review .avatar-review {
  width: 100px;
  height: 100px;
}
