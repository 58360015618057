.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background-color: white;
  color: #0d58a6;
  font-size: 1rem;
  height: 50px;
}

.logo {
  margin-right: auto;
  height: 50px;
  cursor: pointer;
}

.logo-image {
  height: 100%;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  background-color: transparent;
  border: none;
  color: #0d58a6;
  cursor: pointer;
  margin-top: 12px;
  font-weight: bold;
  font-size: 20px;
}

.dropdown-menu {
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  width: 250px;
  background-color: white;
  color: #0d58a6;
  padding: 0.5rem 0;
  margin: 0;
  list-style: none;
  border: 1px solid #fff;
}

.dropdown-menu li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.dropdown-menu li:hover {
  cursor: pointer;
}

.open {
  display: block;
}

.topLeft-container {
  display: flex;
}

.button-css {
  border: none;
  background-color: white;
  font-weight: bold;
  font-size: 20px;
  height: 50px;
  color: #0d58a6;
}

.button-css:hover {
  cursor: pointer;
}

.button-css::selection {
  color: #7fc602;
}

.bottomLeft-container {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 1280px) {
  .navbar {
    flex-wrap: wrap;
    height: 100%;
  }

  .bottomLeft-container {
    justify-content: flex-end;
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .navbar {
    flex-direction: column;
    height: 100%;
  }

  .topLeft-container {
    flex-direction: column;
    width: 100%;
  }

  .bottomLeft-container {
    flex-direction: column;
    width: 100%;
  }
}
